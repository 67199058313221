<template>
	<v-container
        class="my-12"
		fluid>
        <v-row justify="center">
            <v-col cols="auto">
                <h1 class="font-weight-regular">
                    Get Started Mining in a few Easy Steps!
                </h1>
            </v-col>
        </v-row>
		<v-row
            justify="center">
			<v-col
                cols="12"
                md="6">
				<v-card
                    class="mx-5"
                    :color="option === 'a' ? 'transparent' : ''"
                    height="100%"
					flat>
					<v-card-text>
						<v-row justify="center">
							<v-col cols="auto">
								<h1>FREE</h1>
							</v-col>
						</v-row>
						<v-row>
							<v-col>
								<v-divider>
								</v-divider>
							</v-col>
						</v-row>
						<v-row>
							<v-col class="text-center">
								<p
									v-for="(item, key) in compare.free"
									:key="key"
									class="mb-0 title">
									{{item}}
								</p>
							</v-col>
						</v-row>
						<v-row>
							<v-col>
								<v-divider>
								</v-divider>
							</v-col>
						</v-row>
						<v-row justify="center">
							<v-col cols="auto">
								<v-btn
                                    class="primary"
                                    @click="goToCheckout(true)">
									Sign Up
								</v-btn>
							</v-col>
						</v-row>
					</v-card-text>
				</v-card>
			</v-col>
			<v-col
                cols="12"
                md="6">
                <v-card
                    class="mx-5"
                    :color="option === 'b' ? 'grey' : ''"
                    :class="option === 'b' ? 'darken-3' : ''">
					<v-card-text>
						<v-row
							align="center"
							justify="center">
							<v-col cols="auto">
								<v-icon color="warning">
									mdi-star
								</v-icon>
							</v-col>
							<v-col cols="auto">
								<h1 class="warning--text">
									Gold Package
								</h1>
							</v-col>
							<v-col cols="auto">
								<v-icon color="warning">
									mdi-star
								</v-icon>
							</v-col>
						</v-row>
                        <v-row dense>
                            <v-col>
                                <p class="mb-0 text-center">
                                    {{ $getConst('upsellText') }}
                                </p>
                            </v-col>
                        </v-row>
						<v-row>
							<v-col>
								<v-divider>
								</v-divider>
							</v-col>
						</v-row>
						<v-row>
							<v-col class="text-center">
								<p
									v-for="(item, key) in compare.gold"
									:key="key"
									class="mb-0 title"
									v-html="item">
								</p>
							</v-col>
						</v-row>
						<v-row>
							<v-col>
								<v-divider>
								</v-divider>
							</v-col>
						</v-row>
                        <v-row justify="center">
                            <v-col cols="auto">
                                <h2>
                                    <v-icon
                                        class="mt-n1"
                                        left
                                        color="warning">
                                        mdi-check
                                    </v-icon>
                                    1 Year Gold Package
                                </h2>
                            </v-col>
                        </v-row>
						<v-row dense>
							<v-col class="text-center">
								<p class="mb-0 caption">
									Select a subscription right for you
								</p>
							</v-col>
						</v-row>
						<v-row
							class="mt-0"
							justify="center">
							<v-col cols="auto">
								<v-radio-group
									class="mt-0 pt-0"
									hide-details
									mandatory
									v-model="duration"
									row>
									<v-radio
										color="warning"
										value="0">
                                        <template v-slot:label>
                                            <div>
                                                1 Year
                                            </div>
                                        </template>
									</v-radio>
                                    <v-radio
                                        color="warning"
                                        value="1">
                                        <template v-slot:label>
                                            <div>
                                                Lifetime
                                                <span class="warning--text caption">
                                                    (BEST VALUE)
                                                </span>
                                            </div>
                                        </template>
                                    </v-radio>
								</v-radio-group>
							</v-col>
						</v-row>
						<v-row align="center">
							<v-col
                                :cols="{'12': $vuetify.breakpoint.mobile}"
                                :class="{'text-center': $vuetify.breakpoint.mobile}">
								<h1>
                                    <span class="font-weight-regular">Your Total:</span> ${{packages[duration]}} USD
								</h1>
							</v-col>
							<v-col
                                cols="12"
                                sm="auto"
                                :class="{'text-center': $vuetify.breakpoint.mobile}">
								<v-btn
                                    @click="goToCheckout()"
									large
									color="warning">
									Checkout
								</v-btn>
							</v-col>
						</v-row>
                        <v-row>
                            <v-col>
                                <p class="caption font-italic">
                                    Note: MoneroMiner.Rocks does not handle any of your crypto currency payments. All payments are processed through the mining pool and sent directly to your wallet you specified.
                                </p>
                            </v-col>
                        </v-row>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
export default {
    metaInfo: {
        titleTemplate: 'Signup - %s'
    },
	data() {
		return {
            option: 'b',
			compare: {
				free: [
					'5% Miner Fee',
                    'Annoying Ads!',
					'60s Between Pool Updates',
					'Limit 2 Wallet Addresses',
					'Miner Throttle Set 40%',
					'-',
					'-',
					'-',
					'-'
				],
				gold: [
					'Unlimited Wallet Addresses',
                    'Miner Throttle Control',
					'Manage Your Worker',
					'Unlimited Workers',
					'Flight Sheets <span class="primary--text">Coming Soon!</span>',
					'Worker Notifications <span class="primary--text">Coming Soon!</span>',
				]
			},
			packages: [15, 45],
			duration: 0
		}
	},
    methods: {
        goToCheckout(free = false) {
			let url = 'https://monerominer.rocks/membership-account/membership-checkout/?level=4'
			if (!free) {
				switch(this.duration) {
                    case '0':
                        url = 'https://monerominer.rocks/membership-account/membership-checkout/?level=5'
                        break;
                    case '1':
                        url = 'https://monerominer.rocks/membership-account/membership-checkout/?level=8'
                        break;
				}
			}
			window.open(url, "_blank")

        }
    }
}
</script>